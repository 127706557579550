.slick-dots {
  display: flex;
  justify-content: center;
  list-style-type: none;

  li {
    margin: 0 0.25rem;
    background-color: white;
    border-radius: 100%;
  }

  button {
    display: block;
    padding: 6px;
    border: none;
    border-radius: 100%;
    background-color: blue;
    text-indent: -9999px;
  }

  li.slick-active button {
    background-color: #04aa42;
  }
}
