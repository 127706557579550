@import url("https://fonts.googleapis.com/css?family=IBM+Plex+Sans:100,200,300,400,500,600,700");
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  overflow: scroll;
  overflow-x: hidden;
  font-size: 62.5%;
}

html,
body {
  height: 100%;
}

::-webkit-scrollbar {
  width: 0px;
}

body {
  font-family: "IBM Plex Sans", sans-serif !important;
  background-color: #010d17 !important;
  scrollbar-width: none;

  // desktop background image
  @media screen and (min-width: 992px) {
    background-image: url("./Assets/Images/particles@2x.svg");
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: left 200px;
    background-attachment: fixed;
  }

  // mobile background image
  @media screen and (max-width: 992px) {
    background-image: url("./Assets/Images/particles.svg");
    background-size: 950px;
    background-repeat: no-repeat;
    background-position: 10px 320px;
    background-attachment: fixed;
  }
}

html,
body {
  overflow: -moz-scrollbars-none;
}

.tile {
  display: block;
}

@for $i from 1 through 8 {
  .tile {
    &:nth-child(#{$i}) {
      animation-delay: $i * 0.09s;
    }
  }
}

@for $i from 1 through 8 {
  .tile-#{$i} {
    animation: fadeIn 400ms cubic-bezier(0.165, 0.84, 0.44, 1) both;
  }
}

@for $j from 1 through 8 {
  .tileOut-#{$j} {
    animation: fadeOut 400ms cubic-bezier(0.165, 0.84, 0.44, 1) both;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}
