@import url(https://fonts.googleapis.com/css?family=IBM+Plex+Sans:100,200,300,400,500,600,700);
.slick-dots {
  display: flex;
  justify-content: center;
  list-style-type: none; }
  .slick-dots li {
    margin: 0 0.25rem;
    background-color: white;
    border-radius: 100%; }
  .slick-dots button {
    display: block;
    padding: 6px;
    border: none;
    border-radius: 100%;
    background-color: blue;
    text-indent: -9999px; }
  .slick-dots li.slick-active button {
    background-color: #04aa42; }


*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box; }

html {
  overflow: scroll;
  overflow-x: hidden;
  font-size: 62.5%; }

html,
body {
  height: 100%; }

::-webkit-scrollbar {
  width: 0px; }

body {
  font-family: "IBM Plex Sans", sans-serif !important;
  background-color: #010d17 !important;
  scrollbar-width: none; }
  @media screen and (min-width: 992px) {
    body {
      background-image: url(/static/media/particles@2x.22bfd172.svg);
      background-size: auto 100%;
      background-repeat: no-repeat;
      background-position: left 200px;
      background-attachment: fixed; } }
  @media screen and (max-width: 992px) {
    body {
      background-image: url(/static/media/particles.22bfd172.svg);
      background-size: 950px;
      background-repeat: no-repeat;
      background-position: 10px 320px;
      background-attachment: fixed; } }

html,
body {
  overflow: -moz-scrollbars-none; }

.tile {
  display: block; }

.tile:nth-child(1) {
  -webkit-animation-delay: 0.09s;
          animation-delay: 0.09s; }

.tile:nth-child(2) {
  -webkit-animation-delay: 0.18s;
          animation-delay: 0.18s; }

.tile:nth-child(3) {
  -webkit-animation-delay: 0.27s;
          animation-delay: 0.27s; }

.tile:nth-child(4) {
  -webkit-animation-delay: 0.36s;
          animation-delay: 0.36s; }

.tile:nth-child(5) {
  -webkit-animation-delay: 0.45s;
          animation-delay: 0.45s; }

.tile:nth-child(6) {
  -webkit-animation-delay: 0.54s;
          animation-delay: 0.54s; }

.tile:nth-child(7) {
  -webkit-animation-delay: 0.63s;
          animation-delay: 0.63s; }

.tile:nth-child(8) {
  -webkit-animation-delay: 0.72s;
          animation-delay: 0.72s; }

.tile-1 {
  -webkit-animation: fadeIn 400ms cubic-bezier(0.165, 0.84, 0.44, 1) both;
          animation: fadeIn 400ms cubic-bezier(0.165, 0.84, 0.44, 1) both; }

.tile-2 {
  -webkit-animation: fadeIn 400ms cubic-bezier(0.165, 0.84, 0.44, 1) both;
          animation: fadeIn 400ms cubic-bezier(0.165, 0.84, 0.44, 1) both; }

.tile-3 {
  -webkit-animation: fadeIn 400ms cubic-bezier(0.165, 0.84, 0.44, 1) both;
          animation: fadeIn 400ms cubic-bezier(0.165, 0.84, 0.44, 1) both; }

.tile-4 {
  -webkit-animation: fadeIn 400ms cubic-bezier(0.165, 0.84, 0.44, 1) both;
          animation: fadeIn 400ms cubic-bezier(0.165, 0.84, 0.44, 1) both; }

.tile-5 {
  -webkit-animation: fadeIn 400ms cubic-bezier(0.165, 0.84, 0.44, 1) both;
          animation: fadeIn 400ms cubic-bezier(0.165, 0.84, 0.44, 1) both; }

.tile-6 {
  -webkit-animation: fadeIn 400ms cubic-bezier(0.165, 0.84, 0.44, 1) both;
          animation: fadeIn 400ms cubic-bezier(0.165, 0.84, 0.44, 1) both; }

.tile-7 {
  -webkit-animation: fadeIn 400ms cubic-bezier(0.165, 0.84, 0.44, 1) both;
          animation: fadeIn 400ms cubic-bezier(0.165, 0.84, 0.44, 1) both; }

.tile-8 {
  -webkit-animation: fadeIn 400ms cubic-bezier(0.165, 0.84, 0.44, 1) both;
          animation: fadeIn 400ms cubic-bezier(0.165, 0.84, 0.44, 1) both; }

.tileOut-1 {
  -webkit-animation: fadeOut 400ms cubic-bezier(0.165, 0.84, 0.44, 1) both;
          animation: fadeOut 400ms cubic-bezier(0.165, 0.84, 0.44, 1) both; }

.tileOut-2 {
  -webkit-animation: fadeOut 400ms cubic-bezier(0.165, 0.84, 0.44, 1) both;
          animation: fadeOut 400ms cubic-bezier(0.165, 0.84, 0.44, 1) both; }

.tileOut-3 {
  -webkit-animation: fadeOut 400ms cubic-bezier(0.165, 0.84, 0.44, 1) both;
          animation: fadeOut 400ms cubic-bezier(0.165, 0.84, 0.44, 1) both; }

.tileOut-4 {
  -webkit-animation: fadeOut 400ms cubic-bezier(0.165, 0.84, 0.44, 1) both;
          animation: fadeOut 400ms cubic-bezier(0.165, 0.84, 0.44, 1) both; }

.tileOut-5 {
  -webkit-animation: fadeOut 400ms cubic-bezier(0.165, 0.84, 0.44, 1) both;
          animation: fadeOut 400ms cubic-bezier(0.165, 0.84, 0.44, 1) both; }

.tileOut-6 {
  -webkit-animation: fadeOut 400ms cubic-bezier(0.165, 0.84, 0.44, 1) both;
          animation: fadeOut 400ms cubic-bezier(0.165, 0.84, 0.44, 1) both; }

.tileOut-7 {
  -webkit-animation: fadeOut 400ms cubic-bezier(0.165, 0.84, 0.44, 1) both;
          animation: fadeOut 400ms cubic-bezier(0.165, 0.84, 0.44, 1) both; }

.tileOut-8 {
  -webkit-animation: fadeOut 400ms cubic-bezier(0.165, 0.84, 0.44, 1) both;
          animation: fadeOut 400ms cubic-bezier(0.165, 0.84, 0.44, 1) both; }

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-webkit-keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

